<template>
  <HomePage
    class="main-content"
    :background-img="backgroundImg"
    :logo-img="logoImg"
    :swipe-img-list="swipeImgList"
    :app-code="APP_CODE"
    :api-hosts="API_HOSTS"
    :button-style-ios="buttonStyleIOS"
    :button-style-android="buttonStyleAndroid"
    :button-style-pc="buttonStylePC"
    :logo-icon="logoIcon"
    :iosButtonImg="iOSButtonImg"
    :androidButtonImg="androidButtonImg"
  />
</template>

<script>
import HomePage from '@/components/common/home-page/index.vue'
import backgroundImg from './assets/images/bg.png'
import logoImg from './assets/images/logo.png'
import logoIcon from './assets/images/logo_icon.png'
import { APP_CODE, API_HOSTS } from '../config'
import iOSButtonImg from './assets/images/ios_btn.png'
import androidButtonImg from './assets/images/android_btn.png'

export default {
  components: {
    HomePage,
  },
  data() {
    return {
      backgroundImg,
      logoImg,
      logoIcon,
      iOSButtonImg,
      androidButtonImg,
      swipeImgList: [
        {
          bg: require('./assets/images/swipe_bg_1.png'),
          title: require('./assets/images/swipe_title_1.png'),
        },
        {
          bg: require('./assets/images/swipe_bg_2.png'),
          title: require('./assets/images/swipe_title_2.png'),
        },
        {
          bg: require('./assets/images/swipe_bg_3.png'),
          title: require('./assets/images/swipe_title_3.png'),
        },
        {
          bg: require('./assets/images/swipe_bg_4.png'),
          title: require('./assets/images/swipe_title_4.png'),
        },
      ],
      APP_CODE,
      API_HOSTS,
      buttonStyleIOS: {
        border: 'none',
        // backgroundImage: `url(${button_IOS})`,
        // backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        background: 'transparent',
        height: '53px',
      },
      buttonStyleAndroid: {
        // position: 'relative',
        border: 'none',
        // backgroundImage: `url(${button_IOS})`,
        // backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        background: 'transparent',
        height: '53px',
      },
      buttonStylePC: {
        borderRadius: '10px',
        border: '0.5px solid #FFFFDA',
        background: 'linear-gradient(180deg, #FF45F8 0%, #923DFF 57%, #49208B 100%)',
      },
    }
  },
}
</script>

<style>
@import url('../../../assets/css/minireset.min.css');
@import url('../../../assets/css/common.css');
@import url('../common.css');
</style>

<style scoped>
.main-content ::v-deep .home__logo__img {
  height: 40px;
  margin: auto;
}
.main-content ::v-deep .home__bottom--ios > .wrapper__buttons {
  width: 100%;
}

.main-content ::v-deep .home__bottom--android > .wrapper__buttons {
  width: 100%;
}
</style>
